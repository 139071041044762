import { useEffect, useState } from "react";
import { formatDuration, intervalToDuration } from "date-fns";
import { TimeBox } from "./TimeBox";

type TimeObject = {
  value: number
  label: string
}

type TimeSegments = {
  months: TimeObject
  days: TimeObject
  hours: TimeObject
  minutes: TimeObject
}

const weddingDate = new Date(2024, 10, 9).setHours(14)

const extractTimeSegments = (input: string): TimeSegments => {
  const regexPattern: { [key: string]: RegExp } = {
    months: /(\d+)\s*months?/,
    days: /(\d+)\s*days?/,
    hours: /(\d+)\s*hours?/,
    minutes: /(\d+)\s*minutes?/
  };

  const segments: TimeSegments = {
    months: {
      value: 0,
      label: 'miesięcy'
    },
    days: {
      value: 0,
      label: 'dni'
    },
    hours: {
      value: 0,
      label: 'godzin'
    },
    minutes: {
      value: 0,
      label: 'minut'
    }
  };

  for (const segment in segments) {
    const match = input.match(regexPattern[segment]);
    if (match) {
      segments[segment as keyof TimeSegments].value = parseInt(match[1], 10);
    }
  }

  return segments;
}

export const Counter = () => {
  const [timeSegments, setTimeSegments] = useState<TimeSegments>()

  useEffect(() => {
    const updateTimeLeft = () => {
      const now = new Date();
      const duration = intervalToDuration({ start: now, end: weddingDate });
      const formattedDuration = formatDuration(duration, { format: ['months', 'days', 'hours', 'minutes'] });
      setTimeSegments(extractTimeSegments(formattedDuration))
    };
    const intervalId = setInterval(updateTimeLeft, 1000);
    updateTimeLeft();
    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className='text-center py-[30px] sm:py-[70px] xl:container mx-auto'>
      <h1 className='text-4xl mb-[50px] font-playfairDisplay font-bold'>
        Do rozpoczęcia odprawy biletowej <span className='text-darkRed'>9 listopada 2024 r.</span> zostało:
      </h1>
      <div className='flex justify-center gap-2 sm:gap-10'>
        {timeSegments && Object.values(timeSegments).map((segment, id) => (
          <TimeBox key={id} value={segment.value} label={segment.label} />
        ))}
      </div>
    </div>
  )
}
