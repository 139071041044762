import { ReactComponent as Clock } from '../assets/img/clock.svg';
import { ReactComponent as Direction } from '../assets/img/direction.svg';

type LocationDetailProps = {
  time: string
  link: string
}
export const LocationDetail = ({ time, link }: LocationDetailProps) => (
  <div className='flex gap-6 justify-center px-5'>
    <div className='flex gap-3 font-quicksand'>
      <Clock/>
      {time}
    </div>
    <a href={link} target='_blank' rel="noreferrer">
      <div className='flex gap-3'>
        <Direction/>
        <p className='underline hover:text-darkRed font-quicksand'>Jak dojechać?</p>
      </div>
    </a>
  </div>
)