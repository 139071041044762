// TODO: upload full size photos
import rome from '../assets/img/gallery/IMG_9562 1.png'
import iceland from '../assets/img/gallery/IMG_3312 1.png'
import malta from '../assets/img/gallery/IMG_6121 1.png'
import iceland2 from '../assets/img/gallery/IMG_4175a 1.png'
import burglary from '../assets/img/gallery/IMG_9121 1.png'
import iceland3 from '../assets/img/gallery/IMG_7405 1.png'
import iceland4 from '../assets/img/gallery/IMG_2569 1.png'

export const Gallery = () => {
  return (
    <div>
      <h2 className='text-4xl font-playfairDisplay my-[50px] text-center font-bold'>Do zobaczenia wkrótce!</h2>
      <div className='flex sm:gap-6 justify-center'>
        <div className='hidden sm:block'>
          <img src={rome} alt='rome'/>
        </div>
        <div className='flex flex-col sm:gap-6'>
          <img src={iceland2} alt='iceland'/>
          <img src={burglary} alt='burglary'/>
        </div>
        <div className='hidden sm:block'>
          <img src={iceland} alt='iceland'/>
        </div>
        <div className='flex flex-col sm:gap-6'>
          <img src={iceland3} alt='iceland'/>
          <img src={iceland4} alt='iceland'/>
        </div>
        <div className='hidden sm:block'>
          <img src={malta} alt='malta'/>
        </div>
      </div>
    </div>
  )
}
