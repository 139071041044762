import { Card } from "./Card";
import zuza from "../assets/img/zuza.jpg";
import { ContactDetails } from "./ContactDetails";
import stereowizja from "../assets/img/stereowizja.jpg"

export const Support = () => (
  <div className='text-center py-[30px] sm:py-[70px] xl:container mx-auto relative'>
    <h2 className='text-4xl font-playfairDisplay mb-6 sm:mb-12 font-bold'>Wsparcie imprezowe</h2>
    <div className='flex flex-col sm:flex-row justify-center gap-10 sm:gap-20'>
      <Card
        imgSrc={zuza}
        imgAlt='zuza malina'
        title='Zuza Malina'
        subtitle='OPRAWA FOTOGRAFICZNA'
      >
        <ContactDetails
          instagram='https://www.instagram.com/zuza.malina/'
          instagramLabel='Poznaj Zuzę!'
        />
      </Card>
      <Card
        imgSrc={stereowizja}
        imgAlt='stereowizja'
        title='Stereowizja'
        subtitle='oprawa muzyczna'
      >
        <ContactDetails
          instagram='https://www.instagram.com/stereowizja/'
          instagramLabel='Poznaj Bartka i Antka!'
        />
      </Card>
    </div>
  </div>
)
