import { Card } from "./Card";
import helenka from "../assets/img/helenka.jpeg";
import luki from "../assets/img/luki.jpeg";
import planeLeft from '../assets/img/plane-left.png'
import planeRight from '../assets/img/plane-right.png'
import { ContactDetails } from "./ContactDetails";


export const AboutUs = () => (
  <div className='text-center py-[30px] sm:py-[70px] xl:container mx-auto relative'>
    <h2 className='text-4xl font-playfairDisplay mb-6 sm:mb-12 font-bold'>Kierownicy wycieczki</h2>
    <div className='flex flex-col sm:flex-row justify-center gap-10 sm:gap-20'>
      <Card
        imgSrc={helenka}
        imgAlt='church'
        title='Helena Janik'
        subtitle='panna młoda'
        text='Jestem grafikiem i zaprojektowałam tę stronę! Czasami sobie biegam, by być w formie i móc cieszyć się pysznym jedzeniem podczas wielu podróży.'
      >
        <ContactDetails
          phoneNumber='513 694 887'
          mail='janikhelena1@gmail.com'
          fb='https://www.facebook.com/helena.janik.1'
          instagram='https://www.instagram.com/hjanik_?igsh=MWl1YTU5dG1zZnl6ag%3D%3D&utm_source=qr'
        />
      </Card>
      <Card
        imgSrc={luki}
        imgAlt='ballroom'
        title='Łukasz Wysocki'
        subtitle='pan młody'
        text='Programista, pasjonat rowerów i gier – balansuję między kodem a przygodami na dwóch kołach i planszówkowych światach.'
      >
        <ContactDetails
          phoneNumber='516 550 104'
          mail='lwysocki91@gmail.com'
          fb='https://www.facebook.com/profile.php?id=100001074650538'
          instagram='https://www.instagram.com/lwyso91?igsh=MTk2dzE1NW56ZjNobg=='
        />
      </Card>
    </div>
    <div className='hidden sm:block absolute  top-0 -translate-x-1/2'>
      <img src={planeLeft} alt="plane" />
    </div>
    <div className='hidden sm:block absolute top-0 right-0 translate-x-1/2'>
      <img src={planeRight} alt="plane" />
    </div>
  </div>
)