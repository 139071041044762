type TimeBoxProps = {
  value: number
  label: string
}

export const TimeBox = ({ value, label }: TimeBoxProps) => (
  <div className='flex-col'>
    <div className='size-20 sm:size-28 flex justify-center items-center shadow rounded-3xl'>
      <p className='text-darkRed text-3xl font-quicksand'>{value}</p>
    </div>
    <p className='mt-3 font-playfairDisplay'>{label}</p>
  </div>
)
