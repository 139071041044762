import { FormEvent, useEffect, useState } from 'react';

export const JoinUs = () => {
  const [formData, setFormData] = useState({
    name: '',
    notes: '',
    attending: ''
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [notification, setNotification] = useState<{ message: string, type: 'success' | 'error' | '' }>({ message: '', type: '' });
  const [timer, setTimer] = useState(15);

  const isFormIncomplete = !formData.name || !formData.attending || !formData.notes;

  useEffect(() => {
    if (notification.message) {
      const timeout = setTimeout(() => setNotification({ message: '', type: '' }), 15000);
      const interval = setInterval(() => {
        setTimer((prev) => prev - 1);
      }, 1000);

      return () => {
        clearTimeout(timeout);
        clearInterval(interval);
      };
    }
  }, [notification.message]);

  const handleFormChange = ({ name, value }: { name: string, value: string }) => {
    setFormData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();
    setIsSubmitting(true);

    try {
      const response = await fetch('https://wedding-page-be.netlify.app/.netlify/functions/app/send-email', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        console.error('Something went wrong with the email submission:', response.statusText);
        return;
      }

      const data = await response.text();
      setFormData({ name: '', attending: '', notes: '' })
      console.log('Email sent successfully:', data);
      setNotification({ message: 'Dziękujemy za inforamację!', type: 'success' });
      setTimer(15);
    } catch (error) {
      console.error('Error sending email:', error);
      setNotification({ message: 'Błąd wysyłania. Spróbuj ponownie później', type: 'error' });
    } finally {
      setIsSubmitting(false)
    }
  };

  return (
      <div className='relative'>
        {notification.message && (
          <div
            className={`flex justify-between items-center fixed top-0 left-0 right-0 ${notification.type === 'success' ? 'bg-green-800' : 'bg-darkRed'} text-white text-center py-3`}>
            <span className="mx-auto">{notification.message}</span>
            <button
              className="relative mr-4 bg-transparent text-white font-bold"
              onClick={() => setNotification({message: '', type: ''})}
              style={{
                position: 'relative',
                width: '40px',
                height: '40px',
                borderRadius: '50%',
                border: '2px solid white',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <span style={{position: 'relative', zIndex: 2}}>&#10005;</span>
              <div
                className="absolute inset-0 bg-transparent rounded-full"
                style={{
                  background: `conic-gradient(${
                    notification.type === 'success' ? '#66BB6A' : '#D46A6A'
                  } ${timer * (360 / 15)}deg, transparent 0deg)`,
                  transition: 'background 1s linear',
                  width: '100%',
                  height: '100%',
                  zIndex: 1,
                }}
              />
            </button>
          </div>
        )}
        <div className='text-center bg-about-us bg-cover'>
          <div className='bg-white opacity-90 py-[70px]'>
            <div className='md:container mx-auto mb-6'>
              <h2 className='text-xl font-playfairDisplay mb-12'>Dołączysz do nas?</h2>
              <p className='font-quicksand'>
                Powiadom nas o swojej (nie)obecności, wypełniając i wysyłając do nas formularz poniżej. Prosimy aby to
                zrobić do dnia <span className='text-darkRed font-bold'>1 października 2024 r.</span>, abyśmy mogli w
                pełni cieszyć
                się tymi wyjątkowymi chwilami. Dodatkowo w uwagach <span className='font-bold'>prosimy o potwierdzenie czy będą wszystkie zaproszone osoby</span>.
              </p>
              <form onSubmit={handleSubmit} className="mx-auto mt-10">
                <div className='flex w-3/5 mx-auto'>
                  <div className='flex-1'>
                  <div className="flex flex-col mb-4 items-start gap-4">
                    <div>
                      <input
                        type="radio"
                        name="attending"
                        value="yes"
                        checked={formData.attending === 'yes'}
                        className="form-radio"
                        disabled={isSubmitting}
                        onChange={(event) => handleFormChange({name: 'attending', value: event.target.value})}
                      />
                      <span className="ml-2 font-quicksand">Tak, będę z Wami tego dnia!</span>
                    </div>
                    <div>
                      <input
                        type="radio"
                        name="attending"
                        value="no"
                        checked={formData.attending === 'no'}
                        className="form-radio"
                        disabled={isSubmitting}
                        onChange={(event) => handleFormChange({name: 'attending', value: event.target.value})}
                      />
                      <span className="ml-2 font-quicksand">Przykro mi, niestety mnie nie będzie</span>
                    </div>
                  </div>
                </div>
                <div className='flex-1'>
                  <div className="mb-4">
                    <input
                      type="text"
                      name="name"
                      value={formData.name}
                      className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline font-quicksand"
                      placeholder="Imię i nazwisko"
                      disabled={isSubmitting}
                      onChange={(event) => handleFormChange({name: 'name', value: event.target.value})}
                    />
                  </div>
                  <div className="mb-4">
                  <textarea
                    name="notes"
                    value={formData.notes}
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline font-quicksand"
                    placeholder="Uwagi, np.: potrzebuję transportu, potrzebuję noclegu, mam specjalną dietę..."
                    disabled={isSubmitting}
                    onChange={(event) => handleFormChange({name: 'notes', value: event.target.value})}
                  />
                  </div>
                </div>
              </div>
              <button
                type="submit"
                className={`${isFormIncomplete ? 'bg-gray-700' : 'bg-darkRed hover:bg-red-700'}  text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline mt-5 font-quicksand`}
                disabled={isFormIncomplete || isSubmitting}
              >
                WYŚLIJ FORMULARZ
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}
