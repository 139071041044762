import { ReactElement } from "react";

type LocationProps = {
  subtitle: string
  title: string
  text?: string
  imgSrc: string
  imgAlt: string
  children: ReactElement
}

export const Card = ({ subtitle, title, text, imgSrc, imgAlt, children }: LocationProps) => {
  return (
    <div className='w-[90%] sm:w-[400px] mx-auto sm:mx-0'>
      <img className='mb-3 sm:mb-6' src={imgSrc} alt={imgAlt}/>
      <p className='font-quicksand text-neutral-700 text-sm mb-3'>{subtitle.toUpperCase()}</p>
      <p className='font-playfairDisplay text-darkRed text-4xl mb-3 font-bold'>{title}</p>
      <p className='mb-6 font-quicksand'>{text}</p>
      {children}
    </div>
  )
}
