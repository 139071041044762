import { ReactComponent as Phone } from '../assets/img/phone.svg';
import { ReactComponent as At } from '../assets/img/at.svg';
import { ReactComponent as Fb } from '../assets/img/fb.svg';
import { ReactComponent as Instagram } from '../assets/img/instagram.svg';

type ContactDetailsProps = {
  phoneNumber?: string
  mail?: string
  fb?: string
  instagram?: string
  instagramLabel?: string
}
export const ContactDetails = ({ phoneNumber, mail, fb, instagram, instagramLabel }: ContactDetailsProps) => (
  <div>
    {
      phoneNumber && (
        <div className='flex justify-center gap-[25px] mb-5 font-quicksand'>
          <Phone/>
          <p>{phoneNumber}</p>
        </div>
      )
    }
    {
      mail && (
        <div className='flex justify-center gap-[25px] mb-5 font-quicksand'>
          <At/>
          <p>{mail}</p>
        </div>
      )
    }
    <div className='flex justify-center gap-[25px] mb-5'>
      {
        fb && (
          <a href={fb} target='_blank' rel="noreferrer">
            <Fb/>
          </a>
        )
      }
      {
        instagram && (
          <a href={instagram} target="_blank" rel="noreferrer" className="flex items-center gap-2">
            <Instagram/>
            {
              instagramLabel && (
                <span className="flex items-center">{instagramLabel}</span>
              )
            }
          </a>
        )
      }
    </div>
  </div>
)